<script>
import BackIcon from "@/assets/images/misc/back-icon.svg"
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  name: "BackButton",
  components: {SvgIcon,BackIcon},
  props:{
    handler:{
      required:true
    },
    text:{type:String , default:'Back'}
  }
}
</script>

<template>
  <v-btn @click="handler" class="back-button" elevation="0">
    <SvgIcon :text="text">
      <template v-slot:icon>
        <BackIcon/>
      </template>
    </SvgIcon>
  </v-btn>
</template>

<style scoped>
.back-button{
  background: transparent !important;
  color: #112A46 !important;
  font-weight: 500;
  text-transform: capitalize;
}
</style>