<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.2)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                @change="getReport"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="searchParams.facilityId"
                outlined
                placeholder="Facilities"
                item-value="id"
                item-text="name"
                :items="facilities"
                @change="getReport"
                multiple
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
                <template
                  v-if="facilities.length"
                  v-slot:selection="{ item, index }"
                >
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, ...</span>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="q-text-field shadow-0"
                    v-model="date2Formatted"
                    outlined
                    background-color="#fff"
                    readonly
                    v-on="on"
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" style="text-align: center">
              <v-btn-toggle
                borderless
                class="q-tabs"
                v-model="searchParams.mode"
                mandatory
                @change="getReport"
              >
                <v-btn value="d">Daily</v-btn>
                <v-btn value="w">Weekly</v-btn>
                <v-btn value="m">Monthly</v-btn>
                <v-btn value="y">Yearly</v-btn>
              </v-btn-toggle>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>

          <div class="md-card md-theme-default shadow-0 rounded-5">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Facility Usage Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission(
                              $modules.reports.discount.slug
                            )
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table table-striped header-fixed">
                      <thead class="md-card-header sticky shadow-0">
                        <tr>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">
                                Customer Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Start Time</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">End Time</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Hours</div>
                            </div>
                          </th>

                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled text-center"
                            >
                              <div class="md-table-head-label">Revenue</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="facilityUsageReportData.length > 0"
                        :set="
                          ((service = ''),
                          (facility = ''),
                          (total_duration = 0),
                          (total_revenue = 0),
                          (revenue = 0),
                          (duration = 0),
                          (dateFlag = 0))
                        "
                      >
                        <template
                          v-for="(details, index) in facilityUsageReportData"
                        >
                          <tr
                            :key="`datTotal_${index}`"
                            class="md-table-row font-weight-black grand_total_bg"
                            v-if="details.type == 'date' && index > 0"
                            :set="
                              ((active_hours = details.active_hours),
                              (inactive_hours = details.inactive_hours))
                            "
                          >
                            <td class="md-table-cell" colspan="4">
                              <div class="md-table-cell-container text-center">
                                <strong>Total Revenue</strong>
                              </div>
                            </td>

                            <td class="md-table-cell"></td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ currencyCode }}
                                {{ revenue.toFixed(2) }}
                              </div>
                            </td>
                          </tr>

                          <tr
                            :key="`headTotal_${index}`"
                            class="md-table-row font-weight-black grand_total_bg"
                            v-else-if="
                              details.type == 'head' &&
                              index > 2 &&
                              dateFlag == 0
                            "
                            :set="
                              ((active_hours = details.active_hours),
                              (inactive_hours = details.inactive_hours))
                            "
                          >
                            <td class="md-table-cell" colspan="4">
                              <div class="md-table-cell-container text-center">
                                <strong>Total Revenue</strong>
                              </div>
                            </td>

                            <td class="md-table-cell"></td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ currencyCode }}
                                {{ revenue.toFixed(2) }}
                              </div>
                            </td>
                          </tr>
                          <tr
                            :key="`Hours_${index}`"
                            class="md-table-row font-weight-black grand_total_bg"
                            v-else-if="details.type == 'hours' && index > 0"
                          >
                            <td class="md-table-cell" colspan="4">
                              <div class="md-table-cell-container text-center">
                                <strong>Total Hours</strong>
                              </div>
                            </td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                <span
                                  style="margin-right: 12px"
                                  v-if="details.active_hours != null"
                                  >Active -
                                  {{
                                    parseFloat(details.active_hours).toFixed(
                                      2
                                    ) + " hr(s)"
                                  }}</span
                                >
                                <span style="margin-right: 12px" v-else
                                  >Active - Not updated.</span
                                >
                                <span
                                  v-if="
                                    details.inactive_hours != null &&
                                    details.inactive_hours != undefined
                                  "
                                >
                                  Inactive -
                                  {{
                                    parseFloat(details.inactive_hours).toFixed(
                                      2
                                    ) + " hr(s)"
                                  }}</span
                                >
                                <span v-else> Inactive - Not updated</span>
                              </div>
                            </td>

                            <td class="md-table-cell"></td>
                          </tr>
                          <span
                            v-if="details.type == 'date'"
                            :key="`obj_head_${index}`"
                            :set="
                              ((service = ''),
                              (active_hours = details.active_hours),
                              (inactive_hours = details.inactive_hours),
                              (revenue = 0),
                              (dateFlag = 1),
                              (active_hours = details.active_hours),
                              (inactive_hours = details.inactive_hours))
                            "
                          >
                          </span>
                          <tr
                            :key="`obj_head_${index}`"
                            class="name_head"
                            v-if="details.type == 'head'"
                          >
                            <td
                              colspan="6"
                              class="md-table-cell-container text-center font-weight-black"
                              :set="
                                ((service = ''),
                                (facility = ''),
                                (revenue = 0),
                                (dateFlag = 0),
                                (active_hours = details.active_hours),
                                (inactive_hours = details.inactive_hours))
                              "
                            >
                              <div class="md-table-cell-container text-center">
                                {{ details.service_facility }}
                              </div>
                            </td>
                          </tr>
                          <tr
                            :key="`obj_head_${index}`"
                            v-if="
                              details.type == 'data' && details.facility != null
                            "
                            :set="
                              ((service = details.service),
                              (facility = details.facility),
                              (dateFlag = 0))
                            "
                          >
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.date }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.name ? details.name : "-" }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.start_time }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ details.end_time }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center"
                                :set="
                                  ((total_duration += parseFloat(
                                    details.hours
                                  )),
                                  (duration += parseFloat(details.hours)))
                                "
                              >
                                {{
                                  details.hours
                                    ? parseFloat(details.hours).toFixed(2) +
                                      " hr(s)"
                                    : "-"
                                }}
                              </div>
                            </td>

                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center"
                                :set="
                                  ((total_revenue += details.sales),
                                  (revenue += details.sales))
                                "
                              >
                                {{
                                  details.sales && details.sales != null
                                    ? currencyCode +
                                      " " +
                                      details.sales.toFixed(2)
                                    : "0"
                                }}
                              </div>
                            </td>
                          </tr>
                        </template>

                        <template>
                          <tr
                            :key="`datTotal`"
                            class="md-table-row font-weight-black grand_total_bg"
                          >
                            <td class="md-table-cell" colspan="4">
                              <div class="md-table-cell-container text-center">
                                <strong>Total Revenue</strong>
                              </div>
                            </td>

                            <td class="md-table-cell"></td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ currencyCode }}
                                {{ revenue.toFixed(2) }}
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>

                      <tbody v-else>
                        <tr>
                          <td :colspan="columns.length">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        if="facilityUsageReportTotal.length > 0"
                        :set="
                          ((revenue = 0),
                          (active_hours = 0),
                          (inactive_hours = 0))
                        "
                      >
                        <template
                          v-for="(
                            total_details, total_index
                          ) in facilityUsageReportTotal"
                        >
                          <tr
                            :key="`obj_head_total_${total_index}`"
                            class="head_bg"
                            v-if="total_details.type == 'date'"
                          >
                            <td
                              colspan="6"
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              <div class="md-table-cell-container text-center">
                                {{ total_details.date }}
                              </div>
                            </td>
                          </tr>
                          <tr
                            :key="`obj_head_total_${total_index}`"
                            v-else-if="total_details.type == 'data'"
                          >
                            <td></td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{
                                  total_details.service
                                    ? total_details.service
                                    : "-"
                                }}
                              </div>
                            </td>
                            <td class="md-table-cell" colspan="2">
                              <div class="md-table-cell-container text-center">
                                {{ total_details.facility }}
                              </div>
                            </td>
                            <td
                              class="md-table-cell"
                              :set="
                                ((active_hours += total_details.active_hours),
                                (inactive_hours +=
                                  total_details.inactive_hours))
                              "
                            >
                              <div class="md-table-cell-container text-center">
                                <span
                                  style="margin-right: 12px"
                                  v-if="total_details.active_hours != null"
                                  >Active -
                                  {{
                                    parseFloat(
                                      total_details.active_hours
                                    ).toFixed(2) + " hr(s)"
                                  }}</span
                                >
                                <span style="margin-right: 12px" v-else
                                  >Active - Not updated.</span
                                >
                                <span
                                  v-if="
                                    total_details.inactive_hours != null &&
                                    total_details.inactive_hours != undefined
                                  "
                                >
                                  Inactive -
                                  {{
                                    parseFloat(
                                      total_details.inactive_hours
                                    ).toFixed(2) + " hr(s)"
                                  }}</span
                                >
                                <span v-else> Inactive - Not updated</span>
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center"
                                :set="(revenue += total_details.sales)"
                              >
                                {{
                                  total_details.sales &&
                                  total_details.sales != null
                                    ? currencyCode +
                                      " " +
                                      total_details.sales.toFixed(2)
                                    : "-"
                                }}
                              </div>
                            </td>
                          </tr>
                        </template>
                        <template v-if="facilityUsageReportData.length > 0">
                          <tr
                            :key="`hourGrandTotal`"
                            class="md-table-row font-weight-black grand_total_bg sticky_bottom"
                          >
                            <td class="md-table-cell" colspan="4">
                              <div class="md-table-cell-container text-center">
                                <strong>Grand Total - Hours</strong>
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                <span
                                  style="margin-right: 12px"
                                  v-if="active_hours != null"
                                  >Active -
                                  {{
                                    parseFloat(active_hours).toFixed(2) +
                                    " hr(s)"
                                  }}</span
                                >
                                <span style="margin-right: 12px" v-else
                                  >Active - Not updated.</span
                                >
                                <span
                                  v-if="
                                    inactive_hours != null &&
                                    inactive_hours != undefined
                                  "
                                >
                                  Inactive -
                                  {{
                                    parseFloat(inactive_hours).toFixed(2) +
                                    " hr(s)"
                                  }}</span
                                >
                                <span v-else> Inactive - Not updated</span>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr
                            :key="`revGrandTotal`"
                            class="md-table-row font-weight-black grand_total_bg sticky_bottom"
                          >
                            <td class="md-table-cell" colspan="4">
                              <div class="md-table-cell-container text-center">
                                <strong>Grand Total - Revenue</strong>
                              </div>
                            </td>
                            <td class="md-table-cell"></td>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ currencyCode }}
                                {{ revenue.toFixed(2) }}
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      facilityUsageReportData: [],
      facilityUsageReportTotal: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        mode: "w",
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
        venueServiceId: null,
        facilityId: null,
      },
      product_categories: [],
      products: [],
      containerHeight: 400,
      columnData: [
        { name: "Cashier Name", value: "cashier" },
        { name: "Cash", value: "cash" },
        { name: "Card", value: "card" },
        { name: "Credit", value: "credit" },
        { name: "Voucher", value: "voucher" },
        { name: "Complementary", value: "complementary" },
      ],
      columns: [
        "cashier",
        "cash",
        "card",
        "credit",
        "voucher",
        "complementary",
      ],
      facilities: [],
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getFacilities();
      },
    },
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },

    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  methods: {
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },

    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?mode=${this.searchParams.mode}&from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let Facility = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.facilityId) {
        url += `&facility_ids=${this.searchParams.facilityId}`;
        Facility = this.findValueByKey(
          this.facilities,
          this.searchParams.facilityId
        );
      }
      if (Facility !== null) {
        let multiple = "";
        if (this.searchParams.facilityId.length > 1) {
          multiple = "...";
        }
        let facilityName = Facility.name + multiple;
        this.fileName += "-" + facilityName;
      }

      if (venueService !== null) this.fileName += "-" + venueService.name;
      if (this.fileName != null)
        this.fileName =
          "Facility-Usage-Report-" + this.fileName.replace(/\s/g, "");
      else this.fileName = "Facility-Usage-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        if (Array.isArray(key) == true) {
          return opt.id === key[0];
        } else {
          return opt.id === key;
        }
      });
      return match[0] ? match[0] : null;
    },
    getFacilities() {
      //TODO: Available weekdays check
      // let dayname = moment(this.event.date, "YYYY-MM-DD").format("dddd");
      let facility_parameter_url = "";
      if (this.searchParams.venueServiceId != null) {
        facility_parameter_url =
          "?venue_service_id=" +
          this.searchParams.venueServiceId.venue_service_id;
      }
      this.$http
        .get(`venues/facilities/short` + facility_parameter_url)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.facilities = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    // getInactive(curr_date, facility_id) {
    //   let url = `?date=` + curr_date + `&facility_id=` + facility_id;
    //   this.$http
    //     .get(`venues/reports/facility-usage-data${url}`)
    //     .then((response) => {
    //       if (response.status == 200 && response.data.status == true) {
    //         console.log("hereere");
    //         console.log(response.data);
    //         return response.data.data.inactive_hours;
    //       } else {
    //         return false;
    //       }
    //     })
    //     .catch((error) => {
    //       this.errorChecker(error);
    //       this.hideLoader();
    //     });
    // },
    getReport() {
      this.getFacilities();
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/facility-usage-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            const facilityUsage = data;
            this.facilityUsageReportData = [];
            this.facilityUsageReportTotal = [];
            let i = 0;
            let j = 0;
            let currentData = [];
            facilityUsage["data"].forEach((log, index) => {
              if (log.facility !== null) {
                currentData[j] = facilityUsage["data"][index];

                if (i == 0 && log.facility !== null) {
                  this.facilityUsageReportData.push({
                    date: log.date,
                    type: "date",
                  });
                  i++;
                  this.facilityUsageReportData.push({
                    service_facility: log.service + " | " + log.facility,
                    type: "head",
                  });
                  i++;
                } else if (
                  i > 2 &&
                  facilityUsage["data"][index - 1] &&
                  facilityUsage["data"][index - 1].date !=
                    facilityUsage["data"][index].date &&
                  log.facility !== null
                ) {
                  this.facilityUsageReportData.push({
                    type: "hours",
                    inactive_hours: currentData[j - 1].inactive_hours,
                    active_hours: currentData[j - 1].active_hours,
                  });
                  i++;
                  this.facilityUsageReportData.push({
                    date: log.date,
                    type: "date",
                  });
                  i++;
                  this.facilityUsageReportData.push({
                    service_facility: log.service + " | " + log.facility,
                    type: "head",
                  });
                  i++;
                } else if (
                  i > 2 &&
                  facilityUsage["data"][index - 1] &&
                  facilityUsage["data"][index - 1].facility !=
                    facilityUsage["data"][index].facility &&
                  log.facility !== null
                ) {
                  this.facilityUsageReportData.push({
                    type: "hours",
                    inactive_hours: currentData[j - 1].inactive_hours,
                    active_hours: currentData[j - 1].active_hours,
                  });
                  i++;
                  this.facilityUsageReportData.push({
                    service_facility: log.service + " | " + log.facility,
                    type: "head",
                    inactive_hours: log.inactive_hours,
                    active_hours: log.active_hours,
                  });
                  i++;
                }

                if (log.facility !== null) {
                  log.type = "data";
                  this.facilityUsageReportData.push(log);
                  i++;
                }
                j++;
              }
            });
            console.log(currentData[j - 1]);
            if (currentData[j - 1] !== undefined) {
              //  if (isset(currentData[j - 1].inactive_hours)) {
              this.facilityUsageReportData.push({
                type: "hours",
                inactive_hours: currentData[j - 1].inactive_hours,
                active_hours: currentData[j - 1].active_hours,
              });
              i++;
            }
            this.facilityUsageReportTotal.push({
              date:
                this.searchParams.from_date +
                " to " +
                this.searchParams.to_date,
              type: "date",
            });
            facilityUsage["total"].forEach((log) => {
              if (log.facility !== null) {
                log.type = "data";
                this.facilityUsageReportTotal.push(log);
              }
            });
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "facility-usage-report-pdf";
      } else if (type == "excel") {
        link = "facility-usage-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}&type=` + type, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getRentalProducts(start_time, end_time, booking_date, facility_id) {
      this.$http
        .get(
          `venues/facilities/bookings/rentals?start_time=${start_time}&end_time=${end_time}&facility_id=${facility_id}&date=${booking_date}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            console.log("data rental");
            console.log(data);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8;
  z-index: 1;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
.orange-text {
  color: rgb(205, 179, 66) !important;
  caret-color: rgb(205, 179, 66) !important;
}
.name_head {
  background-color: rgb(255, 233, 137) !important;
}
</style>
